import { render, staticRenderFns } from "./leads-add-drawer.vue?vue&type=template&id=0b8de7a2&scoped=true"
import script from "./leads-add-drawer.vue?vue&type=script&lang=js"
export * from "./leads-add-drawer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8de7a2",
  null
  
)

export default component.exports